<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <nav class="responsive-nav -mb-0.5 lg:pl-2">
            <div class="flex justify-between flex-col-reverse lg:flex-row">
              <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
                <ul uk-switcher="connect: #user-tab; animation: uk-animation-fade">
                  <li><a href="#">Tất cả<span>{{ normalUsers.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Bị khóa<span>{{ bannedUsers.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Chặn comment<span>{{ disAllowCommentUsers.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Cho phép upload<span>{{ allowUploadUsers.paginatorInfo.total }}</span></a></li>
                  <li><a href="#">Chưa kích hoạt<span>{{ inactiveUsers.paginatorInfo.total }}</span></a></li>
                </ul>
              </nav>
            </div>
          </nav>

          <form class="mt-4" v-on:submit.prevent="processAction">
            <div class="flex space-x-4">
              <input v-model="q" type="text" class="form-control shadow-none with-border" placeholder="Tìm thành viên"/>
              <select v-model="permission" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Quyền hạn --</option>
                <option value="can_upload">Upload tân nhạc</option>
                <option value="can_update_lyric">Cập nhật lời bài hát</option>
                <option value="can_update_thumbnail">Cập nhật ảnh minh họa</option>
                <option value="can_update_avatar">Cập nhật ảnh đại diện</option>
                <option value="can_manage_sheet">Quản lý sheet nhạc</option>
                <option value="can_manage_comment">Quản lý bình luận</option>
                <option value="can_manage_upload">Quản lý upload</option>
                <option value="can_manage_document">Quản lý tư liệu</option>
                <option value="can_manage_vov">Quản lý khai phá VOV</option>
                <option value="can_manage_people">Quản lý nghệ sĩ</option>
                <option value="can_manage_song">Quản lý bài hát</option>
                <option value="can_manage_tag">Quản lý tag</option>
              </select>
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <optgroup label="Tài khoản">
                  <option value="ban">Khóa nick</option>
                  <option value="unban">Bỏ khóa nick</option>
                  <option value="disallowComment">Chặn bình luận</option>
                  <option value="allowComment">Mở bình luận</option>
                  <option value="disallowUpload">Khóa gửi tác phẩm</option>
                  <option value="allowUpload">Mở gửi tác phẩm</option>
                  <option value="active">Kích hoạt</option>
                  <option value="delete">Xóa tài khoản</option>
                </optgroup>
                <optgroup label="Gán quyền">
                  <option value="upload">Upload tân nhạc</option>
                  <option value="update_lyric">Cập nhật lời bài hát</option>
                  <option value="update_thumbnail">Cập nhật ảnh minh họa</option>
                  <option value="update_avatar">Cập nhật ảnh đại diện</option>
                  <option value="manage_sheet">Quản lý sheet nhạc</option>
                  <option value="manage_comment">Quản lý bình luận</option>
                  <option value="manage_upload">Quản lý upload</option>
                  <option value="manage_document">Quản lý tư liệu</option>
                  <option value="manage_vov">Quản lý khai phá VOV</option>
                  <option value="manage_people">Quản lý nghệ sĩ</option>
                  <option value="manage_song">Quản lý bài hát</option>
                  <option value="manage_tag">Quản lý tag</option>
                </optgroup>
              </select>
              <button :disabled="!selectedUserIds.length || !action" type="submit"
                      class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện <span
                  v-if="selectedUserIds.length">({{ selectedUserIds.length }})</span></button>
            </div>
          </form>

          <div class="uk-switcher lg:mt-8 mt-2" id="user-tab">
            <div class="">
              <table class="w-full">
                <tbody class="divide-y" v-if="normalUsers.data && normalUsers.data.length">
                <user-item unique="normalUsers" v-on:checkedUser="handleCheckedUser" :user="user"
                           v-for="(user, index) in normalUsers.data" :key="'normalUsers' + index"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="normalUsers.paginatorInfo.lastPage > 1"
                    v-model="normalUsers.paginatorInfo.currentPage"
                    :pages="normalUsers.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadNormalUsers"
                />
              </div>
            </div>
            <div class="">
              <table class="w-full">
                <tbody class="divide-y" v-if="bannedUsers.data && bannedUsers.data.length">
                <user-item unique="bannedUsers" v-on:checkedUser="handleCheckedUser" :user="user"
                           v-for="(user, index) in bannedUsers.data" :key="'bannedUsers' + index"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="bannedUsers.paginatorInfo.lastPage > 1"
                    v-model="bannedUsers.paginatorInfo.currentPage"
                    :pages="bannedUsers.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadBannedUsers"
                />
              </div>
            </div>
            <div class="">
              <table class="w-full">
                <tbody class="divide-y" v-if="disAllowCommentUsers.data && disAllowCommentUsers.data.length">
                <user-item unique="disAllowCommentUsers" v-on:checkedUser="handleCheckedUser" :user="user"
                           v-for="(user, index) in disAllowCommentUsers.data" :key="'disAllowCommentUsers' + index"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="disAllowCommentUsers.paginatorInfo.lastPage > 1"
                    v-model="disAllowCommentUsers.paginatorInfo.currentPage"
                    :pages="disAllowCommentUsers.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadADisallowCommentUsers"
                />
              </div>
            </div>
            <div class="">
              <table class="w-full">
                <tbody class="divide-y" v-if="allowUploadUsers.data && allowUploadUsers.data.length">
                <user-item unique="allowUploadUsers" v-on:checkedUser="handleCheckedUser" :user="user"
                           v-for="(user, index) in allowUploadUsers.data" :key="'allowUploadUsers' + index"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="allowUploadUsers.paginatorInfo.lastPage > 1"
                    v-model="allowUploadUsers.paginatorInfo.currentPage"
                    :pages="allowUploadUsers.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadAllowUploadUsers"
                />
              </div>
            </div>
            <div class="">
              <table class="w-full">
                <tbody class="divide-y" v-if="inactiveUsers.data && inactiveUsers.data.length">
                <user-item unique="inactiveUsers" v-on:checkedUser="handleCheckedUser" :user="user"
                           v-for="(user, index) in inactiveUsers.data" :key="'inactiveUsers' + index"/>
                </tbody>
              </table>

              <div class="mt-4">
                <v-pagination
                    class="justify-content-center"
                    v-if="inactiveUsers.paginatorInfo.lastPage > 1"
                    v-model="inactiveUsers.paginatorInfo.currentPage"
                    :pages="inactiveUsers.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadInactiveUsers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import UserItem from "./UserItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {changePageTitle, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "AdminUserList",
  components: {UserItem, VPagination},
  data() {
    return {
      q: "",
      permission: "",
      action: "",
      normalUsers: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      bannedUsers: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      disAllowCommentUsers: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      inactiveUsers: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      allowUploadUsers: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      selectedUserIds: []
    }
  },
  methods: {
    loadNormalUsers() {
      let where = {
        AND: []
      }

      if (this.q) {
        where.AND.push({column: "username", value: this.q + "%", operator: "LIKE"});
      }

      if (this.permission) {
        where.AND.push({column: this.permission, value: 1, operator: "EQ"});
      }

      let query = `query($page: Int, $where: WhereConditions) {
        users(first:10, where: $where, page: $page, orderBy: [{column: "created_at", order:DESC}]) {
          data {
            id
            username
            email
            created_at
            is_banned
            allow_comment
            is_active
            can_upload
            can_update_lyric
            can_update_thumbnail
            can_update_avatar
            auto_approve_upload
            prevent_delete_comment
            hide_comment_homepage
            can_manage_comment
            can_manage_upload
            can_manage_document
            can_manage_people
            can_manage_people
            can_manage_song
            can_manage_sheet
            can_manage_tag
            signup_channel
            last_login_channel
            avatar {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {where: where, page: this.normalUsers.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.normalUsers = data.data.users;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadBannedUsers() {
      let query = `query($page: Int) {
        users(first:10, where: {AND: [{column: "is_banned", value: "Y"}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            email
            username
            created_at
            is_banned
            allow_comment
            is_active
            can_upload
            can_manage_song
            avatar {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.bannedUsers.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.bannedUsers = data.data.users;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadInactiveUsers() {
      let query = `query($page: Int) {
        users(first:10, where: {AND: [{column: "is_active", value: "N"}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            email
            username
            created_at
            is_banned
            allow_comment
            is_active
            can_upload
            can_manage_song
            avatar {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.inactiveUsers.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.inactiveUsers = data.data.users;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadAllowUploadUsers() {
      let query = `query($page: Int) {
        users(first:10, where: {AND: [{column: "can_upload", value: 1}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            email
            username
            created_at
            is_banned
            allow_comment
            is_active
            can_upload
            can_manage_song
            avatar {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.allowUploadUsers.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.allowUploadUsers = data.data.users;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadADisallowCommentUsers() {
      let query = `query($page: Int) {
        users(first:10, where: {AND: [{column: "allow_comment", value: "N"}]}, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            email
            username
            created_at
            is_banned
            allow_comment
            is_active
            can_upload
            can_manage_song
            avatar {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.disAllowCommentUsers.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.disAllowCommentUsers = data.data.users;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    processAction() {
      if (!this.selectedUserIds.length || !this.action) {
        return;
      }

      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let permissions = [
          "upload",
          "update_lyric",
          "update_thumbnail",
          "update_avatar",
          "manage_sheet",
          "manage_comment",
          "manage_upload",
          "manage_document",
          "manage_people",
          "manage_song",
          "manage_tag",
          "manage_vov"
        ];

        if (permissions.includes(this.action)) {
          this.grantPermission();
        } else {
          this.updateBatchUser();
        }
      }
    },
    updateBatchUser() {
      let query = `mutation($ids: [ID!], $update: Mixed!) {
          updateBatchUser(ids: $ids, update: $update)
        }`;

      let update = {};
      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const date = dateObj.getDate();

      switch (this.action) {
        case "ban":
          update["is_banned"] = "Y";
          break;
        case "unban":
          update["is_banned"] = "N";
          break;
        case "disallowComment":
          update["allow_comment"] = "N";
          break;
        case "allowComment":
          update["allow_comment"] = "Y";
          break;
        case "disallowUpload":
          update["can_upload"] = 0;
          break;
        case "allowUpload":
          update["can_upload"] = 1;
          break;
        case "active":
          update["is_active"] = "Y";
          break;
        case "delete":
          update["deleted_at"] = `${year}-${month}-${date} 00:00:00`;
          break;
      }

      ApiService.graphql(query, {ids: this.selectedUserIds, update: update})
          .then(({data}) => {
            if (data.data && data.data.updateBatchUser) {
              this.selectedUserIds = [];
              this.reRender();
            } else {
              alert(data.errors[0].message);
            }
          })
          .catch((response) => {
            alert(response.message);
          });
    },
    grantPermission() {
      let query = `mutation($user_ids: [ID]!, $permission: String!) {
          grantPermissionUsers(user_ids: $user_ids, permission: $permission)
        }`;

      ApiService.graphql(query, {user_ids: this.selectedUserIds, permission: this.action})
          .then(({data}) => {
            if (data.data && data.data.grantPermissionUsers) {
              this.selectedUserIds = [];
              this.reRender();
              this.$toast.success("Cật nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    handleCheckedUser(user, isChecked) {
      if (isChecked) {
        if (!this.selectedUserIds.includes(user.id)) {
          this.selectedUserIds.push(user.id);
        }
      } else {
        this.selectedUserIds = this.selectedUserIds.filter((item) => {
          return item !== user.id;
        });
      }
    },
    reRender() {
      this.loadNormalUsers();
      this.loadBannedUsers();
      this.loadInactiveUsers();
      this.loadAllowUploadUsers();
      this.loadADisallowCommentUsers();
    }
  },
  mounted() {
    if (!isAdmin()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý thành viên");
  },
  watch: {
    q: {
      handler() {
        this.loadNormalUsers();
      }
    },
    permission: {
      handler() {
        this.loadNormalUsers();
      }
    }
  }
}
</script>

<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + user.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + user.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2">
      <router-link class="font-semibold" :to="{name: 'UserDetail', params: {id: user.id}}">
        {{ user.username }}
      </router-link>
    </td>
    <td class="p-2">
      {{ user.email }}
    </td>
    <td class="p-2">
      {{ user.signup_channel }}
    </td>
    <td class="p-2 space-x-2">
      <span v-if="user.is_banned === 'Y'" class="px-2 py-1 text-xs rounded-full bg-red-500 text-white">Khóa nick</span>
      <span v-if="user.allow_comment === 'N'" class="px-2 py-1 text-xs rounded-full bg-red-500 text-white">Chặn bình luận</span>
      <span v-if="user.is_active === 'N'" class="px-2 py-1 text-xs rounded-full bg-red-500 text-white">Chưa kích hoạt</span>
      <span v-if="user.can_upload === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Được gửi tân nhạc</span>
      <span v-if="user.can_update_lyric === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Cập nhật lời</span>
      <span v-if="user.can_update_thumbnail === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Cập nhật ảnh minh họa</span>
      <span v-if="user.can_update_avatar === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Cập nhật ảnh nghệ sĩ, nhạc sĩ</span>
      <span v-if="user.auto_approve_upload === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Tự động duyệt upload</span>
      <span v-if="user.prevent_delete_comment === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Chặn xóa comment</span>
      <span v-if="user.hide_comment_homepage === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Ẩn bình luận trang chủ</span>
      <span v-if="user.can_manage_comment === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý bình luận</span>
      <span v-if="user.can_manage_upload === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý upload</span>
      <span v-if="user.can_manage_document === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý tư liệu</span>
      <span v-if="user.can_manage_people === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý văn nghệ sĩ</span>
      <span v-if="user.can_manage_song === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý bài hát</span>
      <span v-if="user.can_manage_sheet === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý sheet nhạc</span>
      <span v-if="user.can_manage_tag === 1" class="px-2 py-1 text-xs rounded-full bg-green-500 text-white">Quản lý tag</span>
    </td>
    <td class="p-2">
      {{ timeago(user.created_at) }}
    </td>
  </tr>
</template>

<script>

import {timeago} from "../../../core/services/utils.service";

export default {
  name: "AdminUserItem",
  props: {
    user: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
  },
  watch: {
    isChecked: {
      handler(newVal) {
        this.$emit("checkedUser", this.user, newVal);
      }
    },
  }
}
</script>
